import * as React from "react";
import { graphql } from "gatsby";
import { page, Page } from "../classes/Page";
import Layout from "../components/layout/layout";
import { RichTextReact } from "../components/RichText/RichTextReact";
import { gatsbyImg, Img } from "../classes/Img";
import Hero from "../components/ui/Hero";
import { WmkSeo } from "wmk-seo";

interface dataStructure {
  references: {
    internal: { type: string };
    patternImage?: gatsbyImg;
  };
}

const PageNode = ({
  data
}: {
  data: {
    page: page;
    site: { meta: { description: string; siteUrl: string; title: string } };
    patternImage: {
      gatsbyImageData: gatsbyImg["gatsbyImageData"];
      title: string;
    };
  };
}) => {
  const page = new Page(data.page);
  const patternImg = new Img(data.patternImage);
  const path = page.parentPath;
  const modifiedPage = page?.content?.json?.content
    ? page.content.json.content.map((block: dataStructure) => {
        if (block?.references?.internal?.type === "ContentfulBlockPattern") {
          block.references.patternImage = data.patternImage;
          return block;
        } else {
          return block;
        }
      })
    : null;
  return (
    <Layout>
      <WmkSeo.Meta
        title={page.title}
        siteTitle={data?.site?.meta?.title}
        baseUrl={data?.site?.meta?.siteUrl}
        path={path === "/" ? "" : path === null ? "" : path}
        slug={page.slug}
        description={
          page.metaDescription?.metaDescription
            ? page.metaDescription?.metaDescription
            : data?.site?.meta?.description
        }
        ogImageUrl={page?.heroImage?.src}
        twitterHandle=""
        twitterImageUrl=""
      />
      <Hero
        heroHeader={page.heroHeader}
        heroSubheader={page.heroSubheader}
        heroImage={page.heroImage}
        title={page.title}
        patternImage={patternImg}
      />
      <RichTextReact
        content={page.content}
        // space={innerHeight}
        // slug={slug}
      />
    </Layout>
  );
};

export default PageNode;

export const query = graphql`
  query pageQuery($slug: String) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...NodePageFields
      ...PageBlockImageCopy
    }
    patternImage: contentfulAsset(title: { eq: "IT Decorative Tile" }) {
      gatsbyImageData(quality: 80)
      title
    }
    site {
      meta: siteMetadata {
        description
        siteUrl
        title
      }
    }
  }
`;
